import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const handleResponse = (response, alert) => {
    if (response.status === 200) {
        return (
            <SweetAlert
                success
                title={""}
                onConfirm={() => {
                    alert();
                }}
                onCancel={() => {
                    alert();
                }}>
                {response.data.message}
            </SweetAlert>
        );
    } else {
        let alert = null;
        if (response.code === "EXPIRED_TOKEN") {
            //show modal error and signout required
            return (
                <SweetAlert
                    error
                    title={response.message}
                    confirmBtnText='SignIn Again'
                    onConfirm={() => {
                        window.location.href = "/signout";
                    }}>
                    {response.message}
                </SweetAlert>
            );
        } else if (response.code === "VALIDATION_ERROR") {
            return (
                <SweetAlert
                    warning
                    showCancelButton
                    closeOnClickOutside
                    title={"something error"}
                    onConfirm={() => {
                        alert();
                    }}
                    onCancel={() => {
                        alert();
                    }}>
                    {/* {JSON.stringify(response)} */}
                    {response.errors.map((i) => (
                        <p>
                            <b>* {i.field}</b> - {i.message}
                        </p>
                    ))}
                </SweetAlert>
            );
        }
    }
};

export default handleResponse;
