import { FETCH_ALL_QUOTATION, FETCH_SINGLE_QUOTATION } from "../actions/types";

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_ALL_QUOTATION:
            return { ...state, quotations: action.payload };
        case FETCH_SINGLE_QUOTATION:
            return { ...state, quotation: action.payload };
        default:
            return state;
    }
};
