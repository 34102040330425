import React from 'react';

const handleErrorForm = (response) => {

    return <div className="alert alert-danger">
        {
            response.errors.map((value, index) => {
                return Object.keys(value).map((item, i) => (
                    <li key={index}>
                        <small>
                            <strong>{value.field}:</strong> {value.message}
                        </small>
                    </li>
                ));
            })
        }
    </div>

}

export default handleErrorForm