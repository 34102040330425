import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Badge, Pagination, Button } from "react-bootstrap";
import { Edit } from "react-feather";
import * as actions from "../../actions";
import ModalUpdate from "./handleModalDetail";
import handleResponse from "../../function/handleResponse";
import TableLoader from "../../components/Loader/TableLoader";
import moment from "moment";

class Quotation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModalDetail: false,
      limit: 10,
      page: 1,
      total: 0,
      activePage: 1,
      keyword: "",
      alert: null,
      singleData: null,
      status: "",
      id: null,
    };

    this.handleCloseDetail = this.handleCloseDetail.bind(this);
    this.handleShowDetail = this.handleShowDetail.bind(this);
    this.handleSubmitStatus = this.handleSubmitStatus.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handleClickPagination = this.handleClickPagination.bind(this);
    this.emptyAlert = this.emptyAlert.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  /**
   * Detail Handle
   */
  handleCloseDetail() {
    this.setState({ showModalDetail: false });
  }

  handleChangeStatus(status) {
    this.setState({ status: status.target.value });
  }

  convertType(type) {
    if ("PRIVATE_TOUR_UMROH") {
      return "Private Umroh";
    }

    return "[Tidak di ketahui]";
  }

  async handleShowDetail(quoteCode) {
    // get single quotation
    const c = await this.props.fetchSingleQuotation(quoteCode);
    if (c.data) {
      this.setState({
        singleData: c.data.data,
        showModalDetail: true,
        id: c.data.data.id,
      });
    }
  }

  async handleSubmitStatus(event) {
    const result = await this.props.actionStatusQuotation(
      this.state.id,
      this.state.status
    );

    if (result.code === "VALIDATION_ERROR") {
      this.setState({ updateError: result });
    } else {
      this.setState({
        alert: handleResponse(result, this.emptyAlert),
        showModalDetail: false,
      });
    }
  }

  componentWillMount() {
    this.fetchingData();
  }

  async fetchingData() {
    const result = await this.props.fetchAllQuotation(
      this.state.activePage,
      this.state.keyword,
      this.state.limit
    );

    console.log(result.data);

    if (result.status != 200) {
      this.setState({
        singleData: result.data,
        alert: handleResponse(result, this.emptyAlert),
      });
    }
  }

  emptyAlert() {
    this.setState({ alert: null });
  }

  handlePagination() {
    if (this.state.page > 1) {
      let active = this.state.activePage;
      let items = [
        <Pagination.Prev
          key="prev"
          disabled={this.state.activePage == 1 ? "disabled" : false}
          onClick={() => {
            this.handlePrevClickPagination();
          }}
        />,
      ];
      for (let number = 1; number <= this.state.page; number++) {
        items.push(
          <Pagination.Item
            key={number}
            onClick={() => this.handleClickPagination(number)}
            active={number === active}
          >
            {number}
          </Pagination.Item>
        );
      }

      //onClick={this.handleClickPagination(number)}
      items.push(
        <Pagination.Next
          key="next"
          disabled={
            this.state.page == this.state.activePage ? "disabled" : false
          }
          onClick={() => {
            this.handleNextClickPagination();
          }}
        />
      );
      return <Pagination>{items}</Pagination>;
    }
  }

  handleClickPagination(number) {
    this.setState({ activePage: number }, () => {
      this.fetchingData();
    });
  }

  handlePrevClickPagination() {
    this.setState({ activePage: this.state.activePage - 1 }, () => {
      this.fetchingData();
    });
  }

  handleNextClickPagination() {
    this.setState({ activePage: this.state.activePage + 1 }, () => {
      this.fetchingData();
    });
  }

  handleSearch(event) {
    this.setState({ keyword: event.target.value, activePage: 1 }, () => {
      this.fetchingData();
    });
  }

  renderQuotations() {
    this.setState({
      page: this.props.quotations.pages,
      total: this.props.quotations.total,
    });
    return this.props.quotations.data.map((q, index) => {
      let status = "secondary";

      if (q.status === "COMPLETED") {
        status = "success";
      } else if (q.status === "CANCELLED") {
        status = "danger";
      } else if (q.status === "ON_PROCESS") {
        status = "warning";
      } else if (q.status === "BOOKED") {
        status = "primary";
      }

      return (
        <tr key={index} style={{ verticalAlign: "middle" }}>
          <td
            style={{
              fontSize: "10px",
            }}
          >
            {index + (this.state.activePage - 1) * this.state.limit + 1}.
          </td>
          <td>
            <span
              style={{
                fontSize: "10px",
              }}
            >
              {q.user.first_name} {q.user.last_name}
            </span>
          </td>
          <td>
            <span
              style={{
                fontSize: "10px",
              }}
            >
              <div
                style={{
                  fontWeight: 700,
                  color: "#2196F3",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
                onClick={(event) => {
                  this.handleShowDetail(q.code);
                }}
              >
                {q.code}
              </div>
            </span>
          </td>

          <td>
            <strong
              style={{
                fontSize: "10px",
                fontWeight: 600,
              }}
            >
              {moment(q.createdAt).format("DD MMM yyyy - hh:mm A")}
            </strong>
          </td>
          <td>
            <span
              style={{
                fontSize: "10px",
              }}
            >
              {this.convertType(q.type)}
            </span>
          </td>
          <td>
            <span
              style={{
                fontSize: "10px",
              }}
            >
              <Badge
                pill
                variant={status}
                style={{
                  fontSize: "10px",
                }}
              >
                {q.status}
              </Badge>
            </span>
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div>
        {this.state.alert}
        <ModalUpdate
          status={this.state.showModalDetail}
          data={this.state.singleData}
          onClose={this.handleCloseDetail}
          onSubmit={this.handleSubmitStatus}
          onChangeStatus={this.handleChangeStatus}
        />

        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper">
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="page-header">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Quotations
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="row">
                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Control
                              type="search"
                              placeholder="Search Quotation Code"
                              onChange={this.handleSearch}
                            />
                          </div>
                        </div>

                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Traveller</th>
                                <th>Code</th>
                                <th>Di Buat Pada</th>
                                <th>Tipe</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!this.props.quotations ? (
                                <TableLoader col="5" />
                              ) : (
                                this.renderQuotations()
                              )}
                            </tbody>
                          </table>
                          {this.handlePagination()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    quotations: state.quotations.quotations,
  };
};

export default connect(mapStateToProps, actions)(Quotation);
