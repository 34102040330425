import React, { Component } from 'react'
import {Spinner} from 'react-bootstrap'

class Loader extends Component {

    render(){
        return (
            <tr>
                <td colSpan={this.props.col} className="text-center p-3">
                    <Spinner animation="border" role="status" variant="primary">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </td>
            </tr>
        )
    }
}


export default Loader