export const AUTH_USER = "auth_user";
export const UNAUTH_USER = "unauth_user";
export const AUTH_ERROR = "auth_error";
export const FETCH_ERROR = "fetch_error";

export const FETCH_DASHBOARD = "fetch_dashboard";
export const FETCH_DASHBOARD_INFO = "FETCH_DASHBOARD_INFO";
export const FETCH_DASHBOARD_PENDING = "FETCH_DASHBOARD_PENDING";
export const FETCH_ALL_QUOTATION = "FETCH_ALL_QUOTATION";
export const FETCH_SINGLE_QUOTATION = "FETCH_SINGLE_QUOTATION";

export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const FETCH_ONE_PRODUCT = "FETCH_ONE_PRODUCT";

export const FETCH_SCHEDULE = "FETCH_SCHEDULE";
export const FETCH_ONE_SCHEDULE = "FETCH_ONE_SCHEDULE";

export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const FETCH_CATEGORY_LIST = "FETCH_CATEGORY_LIST";

export const FETCH_REVIEW = "FETCH_REVIEW";
export const FETCH_SINGLE_REVIEW_SESSION = "FETCH_SINGLE_REVIEW_SESSION";

export const FETCH_ADDITIONAL_CONDITION = "FETCH_ADDITIONAL_CONDITION";
export const FETCH_LIST_ADDITIONAL_CONDITION =
  "FETCH_LIST_ADDITIONAL_CONDITION";
export const FETCH_ADDITIONAL_CONDITION_CREATE =
  "FETCH_ADDITIONAL_CONDITION_CREATE";
export const FETCH_ADDITIONAL_CONDITION_UPDATE =
  "FETCH_ADDITIONAL_CONDITION_UPDATE";

export const FETCH_TAG = "FETCH_TAG";
export const FETCH_TAG_LIST = "FETCH_TAG_LIST";

export const FETCH_TRANSACTION = "FETCH_BOOKING";
export const FETCH_ONE_TRANSACTION = "FETCH_ONE_BOOKING";

export const FETCH_USER = "fetch_user";
export const FETCH_ONE_USER = "fetch_one_user";

export const FETCH_ACCESS = "fetch_access";

export const FETCH_SETTING = "fetch_setting";

export const FETCH_NOTIFICATION = "fetch_notification";

export const UPLOAD_IMAGE_CATEGORY = "upload_image_category";

export const FETCH_ACTIVITY_COUNTER = "FETCH_ACTIVITY_COUNTER";
export const FETCH_BOOKING_COUNTER = "FETCH_BOOKING_COUNTER";
export const FETCH_USER_COUNTER = "FETCH_USER_COUNTER";
export const FETCH_USER_VERIFY_COUNTER = "FETCH_USER_VERIFY_COUNTER";

export const FETCH_FACILITIES = "FETCH_FACILITIES";
export const FETCH_ONE_FACILITIES = "FETCH_ONE_FACILITIES";
export const FETCH_LIST_FACILITIES = "FETCH_LIST_FACILITIES";

export const FETCH_SLIDER = "FETCH_SLIDER";

export const FETCH_BLOGS = "FETCH_BLOGS";
export const FETCH_ONE_BLOG = "FETCH_ONE_BLOG";

export const FETCH_VOUCHER = "FETCH_VOUCHER";
export const FETCH_ONE_VOUCHER = "FETCH_ONE_VOUCHER";

export const FETCH_METRICS = "FETCH_METRICS";

export const FETCH_ADDITIONAL_PRODUCT = "FETCH_ADDITIONAL_PRODUCT";
export const FETCH_ALL_SAVING = "FETCH_ALL_SAVING";
export const FETCH_ONE_SAVING = "FETCH_ONE_SAVING";

export const FETCH_SCHEDULE_LIST = "FETCH_SCHEDULE_LIST";
