import React, { PureComponent } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import handleErrorForm from "../../function/handleErrorForm";
import moment from "moment";
import "moment/locale/id";

class handleModalDetail extends PureComponent {
  constructor(props) {
    super(props);
  }

  convertType(type) {
    if ("PRIVATE_TOUR_UMROH") {
      return "Private Umroh";
    }

    return "[Tidak di ketahui]";
  }

  render() {
    return (
      <Modal show={this.props.status} size="xl" onHide={this.props.onClose}>
        {!this.props.onError ? "" : handleErrorForm(this.props.onError)}
        <Form onSubmit={this.props.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "15px", fontWeight: 700 }}>
              Detail Quotation {this.props?.data?.code}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-8">
                <table className="table table-bordered table-striped">
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Kode Permintaan
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.code}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Tipe
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.convertType(this.props?.data?.type)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Dibuat pada
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {moment(this.props?.data?.createdAt).format("LLLL")}
                    </td>
                  </tr>
                </table>

                <p style={{ fontSize: "15px", fontWeight: 700 }}>
                  Detail Traveller
                </p>
                <table className="table table-bordered table-striped">
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Nama
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.user.fullname}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Email
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.user.email}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Phone
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.user.phone}
                    </td>
                  </tr>
                </table>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label style={{ marginBottom: "7px",fontSize: "12px" }}>Ubah Status</label>
                  <select
                    className="form-control"
                    style={{fontSize: "12px"}}
                    name="status"
                    onChange={this.props.onChangeStatus}
                    required
                  >
                    <option>-- select one</option>
                    <option value="PENDING" selected={this.props?.data?.status === "PENDING" ? true : false}>PENDING</option>
                    <option value="ON_PROCESS" selected={this.props?.data?.status === "ON_PROCESS" ? true : false}>ON_PROCESS</option>
                    <option value="BOOKED" selected={this.props?.data?.status === "BOOKED" ? true : false}>BOOKED</option>
                    <option value="COMPLETED" selected={this.props?.data?.status === "COMPLETED" ? true : false}>COMPLETED</option>
                    <option value="CANCELLED" selected={this.props?.data?.status === "CANCELLED" ? true : false}>CANCELLED</option>
                  </select>
                  <Button variant='success' size="sm" style={{fontSize: "12px"}} className="mt-2" onClick={() => this.props.onSubmit()}>Simpan</Button>
                </div>
              </div>
              <div className="col-12">
                <p style={{ fontSize: "15px", fontWeight: 700 }}>
                  Rincian Peserta
                </p>
                <table className="table table-bordered table-striped">
                  <thead style={{ background: "#9ba7e8", fontWeight: 600 }}>
                    <tr>
                      <td style={{ fontSize: "12px", textAlign: "center" }}>
                        Tipe Passenger
                      </td>
                      <td style={{ fontSize: "12px", textAlign: "center" }}>
                        Jumlah Passenger
                      </td>
                      <td style={{ fontSize: "12px", textAlign: "center" }}>
                        Request Bed
                      </td>
                    </tr>
                  </thead>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Dewasa
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.raw_data.passengers.adult} Orang
                    </td>
                    <td style={{ background: "#607D8B" }}></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Anak
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.raw_data.passengers.child} Orang
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.raw_data.passengers.child_request_bed}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Bayi
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.raw_data.passengers.infant} Orang
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.raw_data.passengers.infant_request_bed}
                    </td>
                  </tr>
                </table>
                <p style={{ fontSize: "15px", fontWeight: 700 }}>
                  Rincian Permintaan
                </p>
                <table className="table table-bordered table-striped">
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Pergi
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {moment(this.props?.data?.raw_data.departure).format(
                        "dddd, DD MMMM YYYY"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Pulang
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {moment(this.props?.data?.raw_data.arrival).format(
                        "dddd, DD MMMM YYYY"
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Jumlah Hari di Makkah
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.raw_data.day_in_makkah} Hari
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Jumlah Hari di Madinah
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.raw_data.day_in_madinah} Hari
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Total Hari
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.raw_data.day_in_makkah +
                        this.props?.data?.raw_data.day_in_madinah}{" "}
                      Hari
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Referensi Maskapai
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.raw_data.airline}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Direct Flight
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.raw_data.direct_flight}
                    </td>
                  </tr>
                  {this.props?.data?.raw_data.day_in_makkah > 0 ? (
                    <>
                      <tr>
                        <td
                          style={{
                            width: "35%",
                            padding: "10px",
                            background: "#d0d7ff",
                            fontWeight: 600,
                            fontSize: "12px",
                          }}
                        >
                          Referensi Hotel Makkah
                        </td>
                        <td style={{ padding: "10px", fontSize: "12px" }}>
                          {this.props?.data?.raw_data.hotel_makkah}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "35%",
                            padding: "10px",
                            background: "#d0d7ff",
                            fontWeight: 600,
                            fontSize: "12px",
                          }}
                        >
                          Request Tour Thaif
                        </td>
                        <td style={{ padding: "10px", fontSize: "12px" }}>
                          {this.props?.data?.raw_data.use_tour_thaif}
                        </td>
                      </tr>
                      {this.props?.data?.raw_data.use_tour_thaif === "YES" ? (
                        <>
                          <tr>
                            <td
                              style={{
                                width: "35%",
                                padding: "10px",
                                background: "#d0d7ff",
                                fontWeight: 600,
                                fontSize: "12px",
                              }}
                            >
                              Request Hotel Thaif
                            </td>
                            <td style={{ padding: "10px", fontSize: "12px" }}>
                              {this.props?.data?.raw_data.use_hotel_thaif}
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {this.props?.data?.raw_data.day_in_madinah > 0 ? (
                    <>
                      <tr>
                        <td
                          style={{
                            width: "35%",
                            padding: "10px",
                            background: "#d0d7ff",
                            fontWeight: 600,
                            fontSize: "12px",
                          }}
                        >
                          Referensi Hotel Madinah
                        </td>
                        <td style={{ padding: "10px", fontSize: "12px" }}>
                          {this.props?.data?.raw_data.hotel_madinah}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}

                  {this.props?.data?.raw_data.day_in_madinah > 0 &&
                  this.props?.data?.raw_data.day_in_makkah > 0 ? (
                    <>
                      <tr>
                        <td
                          style={{
                            width: "35%",
                            padding: "10px",
                            background: "#d0d7ff",
                            fontWeight: 600,
                            fontSize: "12px",
                          }}
                        >
                          Gunakan Kereta Cepat
                        </td>
                        <td style={{ padding: "10px", fontSize: "12px" }}>
                          {this.props?.data?.raw_data.use_bullet_train}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                  <tr>
                    <td
                      style={{
                        width: "35%",
                        padding: "10px",
                        background: "#d0d7ff",
                        fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Request Tour Leader Indonesia
                    </td>
                    <td style={{ padding: "10px", fontSize: "12px" }}>
                      {this.props?.data?.raw_data.use_tl_indo}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default handleModalDetail;
